@import '@/styles/breakpoints.module';

.feesSection {
  padding: 40px 0;
  @include tablet {
    padding: 80px 0;
  }

  @include desktop {
    padding: 100px 0;
  }
}

.feesSectionContainer {
  display: flex;
  flex-direction: column;

  @include desktop {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
  }

  & > *:last-child {
    @include desktop {
      margin-inline-end: 40px;
    }
  }
}
