@import '@/styles/breakpoints.module';

.navSection {
  flex-basis: 100%;
  word-break: break-all;

  & ~ & {
    @include tablet {
      padding-top: 24px;
    }
  }

  ul {
    margin-top: 12px;
  }

  li:not(:last-child) {
    margin-bottom: 12px;
  }

  h3 {
    font-weight: var(--palette-font-weight-semibold);
  }
}

.mobileNavGrid {
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  width: 100%;

  @include tablet {
    display: none;
  }
}

.mainSiteMobileNavGrid {
  & > *:nth-child(5) {
    grid-row: 3/5;
  }
}

.tabletNav {
  display: none;

  @include tablet {
    flex: 1;
    display: flex;
    gap: 0;
    max-width: 1045px;
  }

  @include desktop {
    justify-content: space-between;
  }
}

.navColumn {
  @include tablet {
    flex-basis: calc(100% / var(--tablet-num-of-columns));
    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  @include desktop {
    flex-basis: calc(100% / var(--tablet-num-of-columns));
  }
}
