@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.body {
}

.title {
  text-align: center;
  margin-bottom: 32px;
  @include tablet {
    margin-bottom: 48px;
  }
}

.separator {
  margin-top: 24px;
  margin-bottom: 24px;
}

// ConverterRow

.converterRow {
  width: 100%;
  display: grid;
  gap: 12px;

  @include tablet {
    gap: 16px;
  }
}

.label {
  color: var(--palette-gray-500);
  width: fit-content;
}

.inputGroup {
  width: 100%;
  display: flex;
  align-items: center;
}

.inputWrapper {
  flex: 1 1 100%;
  margin-inline-end: 16px;
}

.currencyInput {
  width: 100%;
  min-width: 0;
  height: 36px;
  border: none;
  outline: none;
  font: inherit;
  border-bottom: 1px solid transparent;
  caret-color: var(--palette-primary-700);

  @include typography.h4L_;

  &::placeholder {
    @include typography.subheadingL_;
    color: var(--palette-gray-400);
    font-weight: 400;
  }

  &:hover:not(:disabled),
  &:focus {
    border-bottom: 1px solid var(--palette-primary-700);
  }
}

.rates {
  display: flex;
  width: 100%;
  align-items: center;
  color: var(--color-text-primary);
  margin-top: 12px;
  @include tablet {
    margin-top: 16px;
  }
}

.currencyRate {
  width: 140px;
  margin-inline-start: 4px;
}

.footnote {
  text-align: center;
  color: var(--palette-gray-400);
  margin-top: 16px;
}

.error {
  color: var(--palette-red-600);
}
