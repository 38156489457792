.convertersWidgetWrapper {
  position: relative;
  height: 100%;
  overflow: auto;
  margin-bottom: 16px;
  overscroll-behavior: contain;

  .tabsStickyWrapper {
    position: sticky;
    top: 0;
    background-color: white;
    padding: 16px 0;
    z-index: 2;

    .tabsWrapper {
      max-width: 100%;
      width: 100%;
      button {
        width: 100%;
        max-width: 50%;
      }
    }
  }
}
