@import '@/styles/typography.module';


.layoutCards {
  display: grid;
  gap: 16px;
  @include desktop {
    gap: 24px;
  }
}
