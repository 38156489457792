@import 'src/styles/breakpoints.module';

.card.card:global(.MuiAccordion-root) {
  padding: 16px;
  border: 1px solid var(--palette-gray-150);
  border-radius: 12px;
  box-shadow: none;
  background-color: transparent;
  margin: 0;

  transition:
    border 150ms ease-in-out,
    box-shadow 150ms ease-in-out,
    background-color 150ms ease-in-out;

  @include tablet {
    padding: 32px;
  }

  &:hover {
    border: 1px solid var(--palette-primary-700);
    box-shadow: 0 4px 30px rgba(146.41, 150.38, 165.75, 0.2);
    background-color: var(--palette-white);
  }

  &:not(:global(.Mui-expanded)) {
    cursor: pointer;
  }

  :global .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(45deg);
    transition: all 90ms ease-in-out;

    svg {
      color: var(--palette-primary-700);
    }
  }

  &:global(.Mui-expanded) :global(.MuiAccordionSummary-expandIconWrapper) {
    transform: none;
  }

  &:before {
    content: none;
  }

  :global .MuiAccordionSummary-root {
    cursor: pointer;
    padding: 0;
    min-height: 0;
    gap: 12px;

    :global .MuiAccordionSummary-content {
      display: flex;
      justify-content: space-between;
      margin: 0;
      align-items: flex-start;
    }
  }

  :global .MuiAccordionDetails-root {
    padding: 0;
    margin-top: 14px;
    @include tablet {
      margin-top: 16px;
    }
  }
}
