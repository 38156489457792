.icon {
  min-width: 24px;
}

.negative {
  transform: scale(1, -1);
  path {
    stroke: var(--palette-red-400);
  }
}

.positive {
  path {
    stroke: var(--palette-green-400);
  }
}

.filled {
  padding: 4px;
  border-radius: 4px;
  &.positive {
    background-color: var(--palette-green-400);
  }

  &.negative {
    background-color: var(--palette-red-400);
  }
  path {
    stroke: var(--palette-white);
  }
}
