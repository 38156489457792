@import '@/styles/breakpoints.module';

.heading {
  width: 100%;
  margin-bottom: var(--vstack-spacing-sm);

  @include tablet {
    margin-bottom: var(--vstack-spacing-md);
  }

  @include desktop {
    margin-bottom: 0px;
    max-width: 458px;
  }

  .learnMore {
    margin-top: 16px;
    @include tablet {
      margin-top: 24px;
    }
  }
}
