.separator {
  background-color: var(--palette-gray-125);
}

.horizontal {
  width: 100%;
  height: 1px;
}

.vertical {
  width: 1px;
  height: auto;
}
