@import '@/styles/breakpoints.module';

.dialog {
  :global .MuiPaper-root {
    max-width: 552px;
    width: 100%;
  }
}

.qrPopup {
  padding: var(--padding);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.qrHeader {
  & > *:first-child {
    margin-bottom: 8px;
  }

  margin-bottom: 16px;

  @include desktop {
    margin-bottom: 24px;
  }
}

.qrCorners {
  --b: 1px; /* thickness of the border */
  --c: var(--palette-primary-700); /* color of the border */
  --w: 12px; /* width of border */
  --r: 3px; /* radius */

  position: relative;
  max-width: 314px;
  width: 100%;
  aspect-ratio: 1;
  padding: var(--b); /* space for the border */
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: var(--c, red);
    padding: var(--b);
    border-radius: var(--r);
    -webkit-mask:
      linear-gradient(0deg, #000 calc(2 * var(--b)), #0000 0) 50% var(--b) / calc(100% - 2 * var(--w)) 100%
        repeat-y,
      linear-gradient(-90deg, #000 calc(2 * var(--b)), #0000 0) var(--b) 50%/100% calc(100% - 2 * var(--w))
        repeat-x,
      linear-gradient(#000 0 0) content-box,
      linear-gradient(#000 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
  }
}

.qrCode {
  width: 100%;
  max-width: 258px;
  border-radius: 4px;
  border: 1px solid var(--palette-gray-100);
  overflow: hidden;

  @include tablet {
    border-radius: 8px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
