@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
@import '@/styles/button.module';
@import '@/blocks/components/SparklineChart/styles.module';

.table {
  --table-overflow-size: 18px;
  table-layout: fixed;
  width: 100%;
  border-radius: var(--border-radius-table);
  background-color: var(--color-table-row-bg);
  font-weight: var(--font-weight-table-cell);

  @include tablet {
    table-layout: auto;
  }

  td {
    vertical-align: middle;
    @include bodyM_;
    color: var(--color-table-cell-text);
    text-align: start;
  }

  th {
    @include bodyM_;
    color: var(--color-table-heading-text);
    white-space: nowrap;
    vertical-align: middle;
    text-align: start;
  }

  :is(th, td) {
    &:first-child {
      & > div {
        padding-inline: var(--padding-table-cell-first);
      }
    }

    &:last-child {
      text-align: end;

      & > div {
        padding-inline: var(--padding-table-cell-last);
      }

      @include tablet {
        text-align: start;
      }
    }
  }

  tr {
    height: var(--height-table-row);
  }

  tbody tr {
    cursor: pointer;

    &:nth-child(odd) {
      background-color: var(--color-table-stripe-row-bg);
    }

    &:last-child {
      td:first-child {
        border-end-start-radius: var(--border-radius-table);
      }

      td:last-child {
        border-end-end-radius: var(--border-radius-table);
      }
    }

    &:hover {
      --color-trend-negative: white;
      --color-trend-positive: white;

      td > * {
        color: var(--color-table-hover-cell-text);
        --color-text-secondary: var(--color-table-hover-cell-text);
      }

      svg {
        filter: grayscale(1) brightness(10);
      }

      background-color: var(--color-table-row-hover-bg);

      a {
        --color-btn-outline-bg: transparent;
        --color-btn-outline-border: var(--palette-white);
        --color-btn-outline-text: var(--palette-white);

        --color-btn-outline-hover-bg: var(--palette-white);
        --color-btn-outline-hover-border: var(--palette-white);
        --color-btn-outline-hover-text: var(--palette-primary-700);
      }

      @include desktop {
        td:first-child,
        td:last-child {
          position: relative;
          border-radius: 0;

          &:before {
            content: '';
            position: absolute;
            display: block;
            background-color: var(--color-table-row-hover-bg);
            width: var(--table-overflow-size);
            height: 100%;
            top: 0;
          }
        }

        td:first-child:before {
          border-start-start-radius: var(--border-radius-table);
          border-end-start-radius: var(--border-radius-table);
          inset-inline-start: calc(var(--table-overflow-size) * -1);
        }

        td:last-child:before {
          border-start-end-radius: var(--border-radius-table);
          border-end-end-radius: var(--border-radius-table);
          inset-inline-end: calc(var(--table-overflow-size) * -1);
        }
      }
    }
  }
}
