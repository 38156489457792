@import '@/styles/breakpoints.module';
.strategyWidget {
  margin-top: auto;
  border-radius: 8px;
  border: 1px solid var(--blue-border, #3760ff);
  background: var(--colors-primary-50, #fafbff);
  padding: 16px;

  margin-bottom: 16px;

  .avatar.avatar{
    max-width: 70px;
    width: 100%;
    height: 70px;
    overflow: hidden;

    img, svg{
      max-width: 70px;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }


  @include desktop {
    margin-bottom: 32px;
  }

  & > div {
    display: flex;
    align-items: center;
    padding-inline-end: 14px;
  }

  a,
  button {
    width: 100%;
    margin-top: 16px;
  }
}

.strategyAvatar {
  margin-inline-end: 20px;
}

.strategyTitle {
  display: flex;
  align-items: center;
  & > *:first-child {
    font-weight: var(--palette-font-weight-semibold);
    color: var(--color-text-primary);
  }
  & > *:last-child {
    margin-inline-start: 4px;
  }
}

.strategyDescription {
  margin-top: 2px;
  color: var(--palette-gray-400);
}
