.assetCell {
  display: flex;
  align-items: center;

  &,
  * {
    overflow: hidden;
  }

  & > :first-child {
    flex-shrink: 0;
    margin-inline-end: 8px;
  }

  .assetFullName {
    display: block;
    font-weight: var(--palette-font-weight-medium);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .assetName {
    display: block;
    font-weight: var(--palette-font-weight-regular);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.priceCell {
  display: flex;
  align-items: center;

  & > :first-child {
    margin-inline-end: 8px;
  }
}

.priceAndChangeCell {
  width: 100%;

  svg {
    margin-inline-end: 12px;
  }

  & > :first-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  & > :last-child {
    font-weight: var(--palette-font-weight-regular);
    display: flex;
    justify-content: flex-end;
  }
}
