@use '@/styles/typography.module';

.textField:global(.MuiTextField-root) {
  letter-spacing: normal;

  &.MuiTextFieldFilled {
    :global .MuiInputBase-root {
      background-color: var(--palette-white);
    }
  }

  :global .MuiInputBase-root {
    padding-inline-start: 16px;
    padding-inline-end: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 8px;
    background: var(--palette-gray-100);

    &:global(.MuiInputBase-adornedStart) {
      padding-inline-start: 10px;
    }

    &:global(.MuiInputBase-adornedEnd) {
      padding-inline-end: 42px;
    }

    &:hover {
      background: var(--palette-primary-50);

      :global .MuiOutlinedInput-notchedOutline {
        border-color: var(--palette-primary-700);
        box-shadow: 0px 0px 4px 0px rgba(55, 96, 255, 0.5);
      }
    }

    &:global(.Mui-focused) {
      background: var(--palette-white);

      :global .MuiOutlinedInput-notchedOutline {
        border-color: var(--palette-primary-700);
        border-width: 2px;
        box-shadow: none;
      }
    }

    :global .MuiOutlinedInput-input {
      @include typography.bodyM_;
      caret-color: var(--palette-primary-700);
      padding: 0;
      color: var(--palette-gray-900);

      // textarea have height:0 by default during ssr etc
      &:global(.MuiInputBase-inputMultiline)[style='height:0'] {
        min-height: 120px; // 5 rows
      }

      &::placeholder {
        color: var(--palette-gray-400);
      }
    }

    :global .MuiOutlinedInput-notchedOutline {
      border-radius: 8px;
      border: 1px solid var(--palette-gray-125);

      legend {
        visibility: visible;
        background-color: var(--palette-gray-50);
      }

      legend,
      span {
        @include typography.bodyXS_;
        letter-spacing: normal;
      }
    }

    :global .MuiInputAdornment-positionStart {
      color: var(--palette-gray-300);
    }

    :global .MuiAutocomplete-endAdornment {
      top: auto;
      inset-inline-end: 16px;

      :global .MuiAutocomplete-clearIndicator {
        visibility: visible;
        color: var(--palette-gray-900);
        padding: 0px;

        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    &:global(.Mui-error) {
      :global .MuiOutlinedInput-input {
        caret-color: var(--palette-red-600);
      }
      :global .MuiOutlinedInput-notchedOutline {
        border-color: var(--palette-red-600);
      }
      &:hover {
        :global .MuiOutlinedInput-notchedOutline {
          box-shadow: none;
          border-color: var(--palette-red-600);
        }
      }
    }
  }

  --translate-x-input-label: 16px;
  --translate-x-input-label-shrink: 14px;

  &.MuiTextFieldRtl {
    --translate-x-input-label: -16px;
    --translate-x-input-label-shrink: -14px;
  }

  &.MuiTextFieldAdornedStart {
    --translate-x-input-label: 42px;

    &.MuiTextFieldRtl {
      --translate-x-input-label: -42px;
    }
  }

  :global .MuiInputLabel-root {
    @include typography.bodyM_;
    transform: translate(var(--translate-x-input-label), 10px) scale(1);
    letter-spacing: normal;

    &:global(.Mui-focused) {
      color: var(--palette-primary-700);
    }

    &:global(.Mui-error) {
      color: var(--palette-red-600);
    }

    &:global(.MuiInputLabel-shrink) {
      transform: translate(var(--translate-x-input-label-shrink), -9px) scale(0.75);
    }

    :global .MuiFormLabel-asterisk {
      color: inherit;
    }
  }

  :global .MuiFormHelperText-root {
    &:global(.Mui-error) {
      color: var(--palette-red-600);
    }
  }
}
