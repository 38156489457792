@import '@/styles/breakpoints.module';

.informationSectionWrapper {
  margin: 16px 0;
  padding: 16px;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--palette-gray-600);

  @include tablet {
    margin: 24px 0;
  }
}

.informationSection {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--color-text-secondary);

  p:not(:first-child) {
    margin-top: 8px;
  }
}
