@import '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.logoWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
  height: fit-content;

  .stars {
    img {
      width: 108px;
      height: auto;
    }
  }
}
