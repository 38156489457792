@import '@/styles/breakpoints.module';

.slide {
  &:not(:global(.is-active)) .imageWrapper {
    @include tablet {
      transform: scale(0.85);
    }
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 1.5;
  border-radius: var(--palette-border-radius-xm);
  box-shadow: var(--shadow-cards-dark);
  overflow: hidden;

  transition: all 200ms ease-in-out;

  img {
    object-fit: cover;
  }

  @include tablet {
    width: 100%;
    height: 100%;
    border-radius: var(--palette-border-radius-xxl);
  }
}
