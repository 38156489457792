[dir='rtl'] {
  .dirDependent {
    transform: scaleX(-1);
  }
}

//:dir(ltr) {
//  .dirDependent {
//    background-color: red;
//    //transform: scaleX(-1);
//  }
//}
//:dir(rtl) {
//  .dirDependent {
//    transform: scaleX(-1);
//  }
//}

//  :dir(rtl) {
//    transform: scaleX(-1);
//  }
//}

@mixin rtl($property, $ltr-value, $rtl-value) {
  #{$property}: $ltr-value;

  [dir='rtl'] & {
    #{$property}: $rtl-value;
  }
}
