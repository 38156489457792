@import '@/styles/breakpoints.module';

.avatar {
  position: relative;
  max-width: 108px;
  min-width: 108px;
  height: auto;

  & > svg,
  & > img {
    width: 100%;
    height: auto;
  }

  @include tablet {
    max-width: 165px;
    min-width: 165px;
  }

  .avatarVerifiedIndicator {
    position: absolute;
    inset-inline-start: calc(50% + 20%);
    bottom: 0;
  }
}
