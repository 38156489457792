@import '@/styles/breakpoints.module';

.buyOrTradeTabs {
  width: 100%;
  max-width: none !important;
  margin-bottom: 24px;
}

.buyOrTradeCard {
  :global .MuiTabPanel-root {
    padding: 0px;
  }
}
