@import '@/styles/breakpoints.module';

.footer {
  --footer-background: linear-gradient(229deg, #4b4b4b 1.87%, #0a0a0a 95.11%);
  --footer-text-color: var(--color-text-secondary);
  --footer-link-color: var(--palette-gray-150);
  --footer-border-color: var(--palette-gray-500);
  --footer-pipe-color: var(--palette-gray-500);
  --footer-nav-title-color: var(--palette-white);
  --footer-section-border-color: var(--footer-border-color);

  font-size: 12px;
  line-height: 16px;
  padding: 0 8px 16px 8px;

  @include tablet {
    padding: 0 20px 20px 20px;
  }

  @include desktop {
    padding: 0 30px 30px 30px;
  }

  a {
    color: var(--footer-link-color);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  h1,
  h2,
  h3 {
    color: var(--footer-nav-title-color);
  }

  h4,
  h5,
  h6 {
    font-weight: var(--palette-font-weight-bold);
    color: var(--palette-gray-150);
  }
}

.footerInner {
  background: var(--footer-background);
  color: var(--footer-text-color);
  border-radius: 12px;

  @include desktop {
    border-radius: 18px;
  }
}

.footerContainer {
  padding: 16px 12px 0 12px;
  max-width: 1300px;
  margin: 0 auto;
  box-sizing: content-box;
  @include desktop {
    padding: 40px 40px 0 40px;
  }
}

.productsSection {
  display: flex;
  flex-direction: column;
  gap: 24px;

  @include desktop {
    flex-direction: row;
    align-items: flex-start;
    gap: clamp(2rem, 1.5vw, 3.25rem);
    justify-content: space-between;
  }
}
