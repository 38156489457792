@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';

.mobile {
  display: block;
  @include tablet {
    display: none;
  }
}

.tablet {
  display: none;
  @include tablet {
    display: block;
  }
}

.cardsBlock {
  display: grid;
  gap: 24px;

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }

  @include desktop {
    gap: 30px;
  }
}

.btnSpec {
  width: 100%;
  @include tablet {
    width: auto;
  }
}

.bgWrapper {
  @include desktop {
    background: url('images/worldmap1x.png') no-repeat center;
    padding-top: 86px;
    height: 538px;
  }
}

.bgImage {
  display: none;
  @include desktop {
    display: block;
  }
}
