@import '@/styles/breakpoints.module';

.qrCode {
  display: none;
  width: 100px;
  height: auto;
  margin-top: 40px;
  border-radius: 12px;

  @include desktop {
    display: block;
  }
}
