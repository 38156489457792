@import '@/styles/breakpoints.module';

.cardsGridLayout {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 24px;
  @include desktop {
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr;
  }
}

.voucherGrid {
  display: grid;
  gap: 24px;

  @include desktop {
    grid-template-columns: 1fr 1fr;
    gap: var(--cards-grid-gap);

    & > *:first-child {
      grid-column: 1 / -1;
    }
  }
}
