.chartNegative {
  stroke-width: 1.5px;
  stroke: var(--color-sparkline-chart-negative-stroke);

  defs linearGradient stop {
    stop-color: var(--color-sparkline-chart-negative-fill);
  }
}

.chartPositive {
  stroke-width: 1.5px;
  stroke: var(--color-sparkline-chart-positive-stroke);

  defs linearGradient stop {
    stop-color: var(--color-sparkline-chart-positive-fill);
  }
}
