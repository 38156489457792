@import '@/styles/breakpoints.module';

.logo {
  color: var(--palette-white);
}

.header {
  position: fixed;
  top: 0;
  padding: 0 16px;
  background-color: var(--color-header-bg);
  backdrop-filter: blur(3px);
  width: 100%;
  z-index: 1300;
  height: var(--height-header);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include tablet {
    padding: 0 20px;
  }
}
