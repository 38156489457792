@import '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.africaLogo {
  display: flex;
  flex-direction: column;
  gap: 16px;

  a {
    color: var(--palette-white) !important;
  }

  @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @include desktop {
    gap: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
}

.waitlistInfoLinkWrapper {
  padding-bottom: var(--vstack-spacing-sm);
}

.waitlistInfoLink {
  color: var(--palette-gray-200);
}
