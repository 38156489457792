@import '@/styles/breakpoints.module';

.headerMini {
  width: 100%;
  position: fixed;
  nav {
    align-items: center;
    justify-content: flex-start;
    height: var(--height-header);
    display: flex;
  }
}
