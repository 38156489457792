@import './../styles.module';
.cellPriceAndChange {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;

  .cellPriceAndChangePrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2px;
    img,
    svg {
      margin-inline-end: 12px;
    }
  }

  .cellPriceAndChangeChange {
    text-align: end;
  }
}
