@import '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.appBlock {
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;

  @include tablet {
    gap: 24px;
    flex-direction: row;
  }

  @include desktop {
    flex-direction: column;
  }
}

.appBlockContent {
  display: grid;
  gap: 16px;

  @include tablet {
    gap: 24px;
  }
}

.rowLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  width: 100%;
}

.logoLink {
  flex-shrink: 0;
  width: 109px;

  svg {
    width: 100%;
    height: auto;
  }
}
