@import '@/styles/breakpoints.module';

.statItems {
  display: flex;
  justify-content: center;
  @include tablet {
    justify-content: flex-start;
  }
  gap: 12px;
  align-items: center;
  flex-wrap: wrap;
}

.statItem {
  display: flex;
  align-items: center;
  .statItemName {
    margin-inline-start: 6px;
  }
}

.dot {
  width: 4px;
  height: 4px;
  background-color: var(--palette-primary-200);
  border-radius: 100%;
}
