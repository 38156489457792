@import '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.legal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;

  gap: 12px;

  @include tablet {
    margin-top: 24px;
    margin-bottom: 24px;
    gap: unset;
  }

  @include desktop {
    flex-direction: row;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.legalLinks {
  li {
    margin-top: 4px;
    margin-inline-end: 4px;
    display: inline-block;

    @include tablet {
      margin-top: 12px;
      margin-inline-end: 12px;
    }

    @include desktop {
      margin-top: 0;
    }

    &:after {
      content: '';
      position: absolute;
      height: 12px;
      width: 1px;
      background-color: var(--footer-pipe-color);
      margin: 3px 0;
    }

    &:last-child {
      margin-inline-end: 0;

      &:after {
        content: none;
      }

      a {
        padding-inline-end: 0;
      }
    }
  }

  a {
    padding-inline-end: 4px;
    display: inline-block;
    white-space: nowrap;

    @include tablet {
      padding-inline-end: 12px;
    }
  }
}
