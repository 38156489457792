@import '@/styles/breakpoints.module';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 34px;
}

.iconBox {
  position: absolute;
  top: 0;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--icon-box-color);
  background: var(--colors-primary-400);
  border-radius: var(--icon-box-border-radius);
  box-shadow: var(--icon-shadow);

  img,
  svg {
    width: 32px;
    height: 32px;
  }
}

.content {
  flex: 1;
  width: 100%;
  text-align: center;
  background: var(--color-card-bg);
  border-radius: var(--top-icon-card-border-radius);
  box-shadow: var(--top-icon-card-shadow);
}

.description {
  margin-top: 8px;
}

.separator {
  margin-top: 16px;
  margin-bottom: 16px;

  @include tablet {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.sizeS {
  padding: 48px 16px 24px;
}

.sizeM {
  padding: 48px 16px 16px;
  @include tablet {
    padding: 60px 32px 32px;
  }
  @include desktop {
    padding: 60px clamp(16px, 2vw, 32px) 32px;
  }
}

.bgPrimary {
  background-color: var(--palette-primary-400);
}

.bgOrange {
  background-color: var(--palette-light-orange);
}

.bgPink {
  background-color: var(--palette-pink);
}

.bgRed {
  background-color: var(--palette-raswberry);
}

.bgGreen {
  background-color: var(--palette-green-400);
}

.bgYellow {
  background-color: var(--palette-yellow-400);
}

.bgViolet {
  background-color: var(--palette-violet);
}

.cardList {
  text-align: start;

  & > * + * {
    margin-top: 12px;
    @include tablet {
      margin-top: 16px;
    }
  }
}

.listItem {
  position: relative;
  padding-inline-start: 12px;
  color: var(--color-text-primary);

  &:before {
    content: '';
    position: absolute;
    inset-inline-start: 0;
    top: 8px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: var(--palette-primary-700);
  }
}
