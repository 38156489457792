@import '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.tabs {
  --border-width: 4px;
  --padding-y: 16px;
  --padding-x: 12px;

  // TL;DR: HACK for tab bottom border dont conflict with the selected tab indicator before initial render
  // PROBLEM: because selected tab indicator located directly in the selected tab button before initial render
  // SOLUTION: so im detecting not client rendered yet tab with [style^='overflow:hidden;'] :))) and remove border + fill border space with extra padding
  // NOTE: [style^='overflow:hidden;'] can be replaced with js logic that including usage of isClient hook or smth like this
  :global(.MuiTabs-scroller[style^='overflow:hidden;'] .MuiTab-root.Mui-selected) {
    border: none;
    padding-bottom: calc(var(--padding-y) + var(--border-width));
  }

  :global .MuiTab-root {
    @include bodyM_;
    padding: var(--padding-y) var(--padding-x);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-tab-text);
    text-transform: none;
    border-bottom: var(--border-width) solid var(--color-tab-border);
    font-family: inherit;
    font-weight: var(--font-weight-btn);
    flex-grow: 1;
    max-width: none;

    &:hover {
      color: var(--color-tab-hover-text);
    }

    &:global(.Mui-selected) {
      color: var(--color-tab-active-text);
    }
  }

  :global .MuiTabs-indicator {
    border-radius: 6px;
    background: var(--colors-primary-700, #3760ff);
    height: var(--border-width);
  }
}

.tabsFilled {
  --padding-y: 8px;

  @include tablet {
    --padding-y: 24px;
  }

  svg,
  img {
    width: 16px;
    height: 16px;
    margin-inline-end: 4px;

    @include tablet {
      width: 24px;
      height: 24px;
      margin-inline-end: 12px;
    }
  }

  :global .MuiTab-root {
    @include bodyM_;
    @include tablet {
      @include h5Tablet_;
    }
  }

  :global .MuiTabs-indicator {
    background: linear-gradient(180deg, rgba(41, 85, 255, 0) 0%, rgba(41, 85, 255, 0.07) 100%);
    height: 100%;
    &:after {
      position: absolute;
      content: '';
      display: block;
      height: var(--border-width);
      border-radius: 6px;
      background: var(--colors-primary-700, #3760ff);
      bottom: 0;
      inset-inline-start: 0;
      width: 100%;
    }
  }
}

.outlinedTabs:global(.MuiTabs-root) {
  border-radius: 8px;
  padding: 4px;
  min-height: 0;
  background: var(--colors-grey-100, #f0f0f0);

  :global .MuiTabs-flexContainer {
    justify-content: space-between;
    @include tablet {
      justify-content: flex-start;
    }
  }

  @include tablet {
    max-width: max-content;
  }

  :global .MuiTab-root {
    min-height: 0;
    z-index: 1;
    padding: 6px 10px;
    @include bodyM_;
    font-weight: var(--palette-font-weight-semibold);
    color: var(--palette-gray-400);
    text-transform: none;

    &:global(.Mui-selected) {
      :global .MuiTabs-indicator {
        z-index: -1;
      }

      color: var(--palette-primary-700);
    }
  }

  :global .MuiTabs-indicator {
    height: 100%;
    background-color: var(--palette-white);
    border-radius: 8px;
  }
}

.tabStartIcon {
  svg {
    display: none;
    @include tablet {
      display: block;
      width: 24px;
      height: 24px;
      margin-inline-end: 8px;
    }
  }
}
