@import '@/styles/breakpoints.module';

.heroBody {
  margin-top: 28px;
  @include tablet {
    margin-top: 64px;
  }
  @include desktop {
    margin-top: 0;
    max-width: 555px;
    width: 100%;
    margin-inline-start: auto;
  }

  border-radius: var(--border-radius-small-assets-table);
  background-color: var(--color-small-assets-table-bg);
  width: 100%;
  height: 100%;
  padding: 16px;

  @include tablet {
    padding: 24px 16px;
  }
}

.ctaContainer {
  text-align: center;
  margin-top: 16px;
}

.tabsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.tableRow {
  border-bottom: 1px solid var(--palette-gray-100);

  &:first-child {
    border-top: 1px solid var(--palette-gray-100);
  }

  &:last-child {
    border-bottom: none;
  }
}

.table.table {
  --table-overflow-size: 0px;
  --height-table-row: 64px;
  --padding-table-cell: 12px 8px;
  --padding-table-cell-last: 8px 12px;
  --padding-table-cell-first: 12px 8px;
  font-weight: var(--palette-font-weight-regular);

  @include tablet {
    table-layout: fixed;
  }

  @include desktop {
    table-layout: unset;
  }

  th,
  td {
    &:not(:nth-child(-n + 2)) {
      display: none;
      @include tablet {
        display: table-cell;
      }
    }

    &:nth-child(2) {
      text-align: end;
      @include tablet {
        display: none;
      }
    }
  }

  td {
    &:nth-child(1) {
      font-weight: var(--font-weight-small-assets-table-asset-cell);
      color: var(--color-text-primary);
    }

    &:nth-child(3) {
      color: var(--color-small-assets-table-regular-cell-text);
    }
  }
}

.cellAsset {
  @include desktop {
    width: 190px;
  }
}

.cellPrice {
  @include desktop {
    width: 120px;
  }
}

.cellPct {
  font-weight: var(--palette-font-weight-medium);
}
