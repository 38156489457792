@use '@/styles/typography.module';
@import '@/styles/breakpoints.module';

.wrapper {
  @include desktop {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.image {
  display: block;
  width: 100%;
  height: auto;
  margin-top: 24px;
  margin-inline: auto;
  border-radius: var(--image-wrapper-border-radius-s);

  @include tablet {
    margin-top: 32px;
    width: 400px;
    flex-shrink: 0;
    border-radius: var(--image-wrapper-border-radius-m);
  }

  @include desktop {
    margin-top: 0;
    margin-inline: 0;
    margin-inline-start: 60px;
  }
}

.content {
  p {
    margin-bottom: 24px;
    @include tablet {
      margin-bottom: 32px;
    }
  }

  a {
    width: 100%;
    @include tablet {
      width: auto;
    }
  }
}

.noPaddingBottom {
  padding-bottom: 0 !important;
}

.ctaBlockContainer {
  border-radius: var(--border-radius-cta-block-container);
  padding: 24px 16px;

  @include tablet {
    padding: 40px;
  }
  @include desktop {
    padding: 60px;
  }
}
