@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
//@import '@/styles/theme';

.commonContainer {
  padding: 40px 8px;
  @include tablet {
    padding: 80px 20px;
  }
  @include desktop {
    padding: 100px 20px;
  }
  @include large-desk {
    padding: 100px 30px;
  }
}

.sizeBlock {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.roundedLayoutCard {
  border-radius: 12px;
  padding: 40px 8px;
  @include tablet {
    padding: 80px 40px;
    border-radius: 18px;
  }
  @include desktop {
    padding: 120px 40px;
  }
  @include large-desk {
    padding: 120px;
  }
}

.sectionTitle {
  margin-bottom: 32px;
  @include tablet {
    margin-bottom: 48px;
  }
  @include desktop {
    margin-bottom: 60px;
  }
}
