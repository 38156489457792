@use 'typography.module';
@use 'breakpoints.module';

// WARNING
// svg path { fill: .... } entries removed, please use an svg currentcolor attributes value instead

// Regular buttons usage
// base - .btn
// size - .btn{ L | M | S | XS | XXS } or .btnSocial{ L | S }
// icon - .btn{ IconLeft | IconRight | Icon }
// variant - .btn{ Primary | Filled | Outline }

// Social buttons usage (appstore, reddit etc)
// base - .btn
// size - .btnSocial{ L | S }
// variant - .btn{ SocialOutline | SocialFilled }

.btn {
  appearance: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  outline: none;
  //white-space: nowrap;
  user-select: none;
}

@mixin btnL_ {
  @include typography.buttonL_;
  padding: 16px 32px;
  border-radius: 8px;

  svg,
  img {
    width: 32px;
    height: 32px;
  }

  &.btnIconLeft {
    padding-inline-start: 16px;

    svg,
    img {
      margin-inline-end: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-end: 4px;
      }
    }
  }

  &.btnIconRight {
    padding-inline-end: 16px;

    svg,
    img {
      margin-inline-start: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-start: 4px;
      }
    }
  }

  &.btnIcon {
    padding: 16px;
  }
}

@mixin btnM_ {
  @include typography.buttonM_;
  padding: 12px 24px;
  border-radius: 8px;

  svg,
  img {
    width: 24px;
    height: 24px;
  }

  &.btnIconLeft {
    padding-inline-start: 12px;

    svg,
    img {
      margin-inline-end: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-end: 4px;
      }
    }
  }

  &.btnIconRight {
    padding-inline-end: 12px;

    svg,
    img {
      margin-inline-start: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-start: 4px;
      }
    }
  }

  &.btnIcon {
    padding: 12px;
  }
}

@mixin btnS_ {
  @include typography.buttonS_;
  padding: 12px 20px;
  border-radius: 8px;

  svg,
  img {
    width: 16px;
    height: 16px;
  }

  &.btnIconLeft {
    padding-inline-start: 12px;

    svg,
    img {
      margin-inline-end: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-end: 4px;
      }
    }
  }

  &.btnIconRight {
    padding-inline-end: 12px;

    svg,
    img {
      margin-inline-start: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-start: 4px;
      }
    }
  }

  &.btnIcon {
    padding: 14px;
  }
}

@mixin btnXS_ {
  @include typography.buttonXS_;
  padding: 10px 16px;
  border-radius: 8px;
  svg,
  img {
    width: 16px;
    height: 16px;
  }
  &.btnIconLeft {
    padding-inline-start: 12px;

    svg,
    img {
      margin-inline-end: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-end: 4px;
      }
    }
  }
  &.btnIconRight {
    padding-inline-end: 12px;

    svg,
    img {
      margin-inline-start: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-start: 4px;
      }
    }
  }
  &.btnIcon {
    padding: 12px;
  }
}

@mixin btnXXS_ {
  @include typography.buttonXS_;
  padding: 8px 16px;
  border-radius: 6px;

  svg,
  img {
    width: 16px;
    height: 16px;
  }

  &.btnIconLeft {
    padding-inline-start: 8px;

    svg,
    img {
      margin-inline-end: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-end: 4px;
      }
    }
  }
  &.btnIconRight {
    padding-inline-end: 8px;

    svg,
    img {
      margin-inline-start: 8px;
    }

    &.btnLink {
      svg,
      img {
        margin-inline-start: 4px;
      }
    }
  }
  &.btnIcon {
    padding: 8px;
  }
}

.btnL {
  @include btnL_;
}

.btnM {
  @include btnM_;
}

.btnS {
  @include btnS_;
}

.btnXS {
  @include btnXS_;
}

.btnXXS {
  @include btnXXS_;
}

.btnResponsiveML {
  @include btnM_;
  @include breakpoints.tablet {
    @include btnL_;
  }
}

.btnResponsiveXXSM {
  @include btnXXS_;
  @include breakpoints.tablet {
    @include btnM_;
  }
}

.btnResponsiveSM {
  @include btnS_;
  @include breakpoints.tablet {
    @include btnM_;
  }
}

.btnPill {
  @include btnXXS_;
  @include typography.buttonS_;

  border-radius: var(--palette-border-radius-s);
  border: 1px solid var(--palette-gray-150);
  background: var(--palette-white);
  color: var(--palette-gray-900);
  font-weight: var(--font-weight-btn);

  &.btnPillTransparent {
    background: transparent;
  }

  &:hover {
    border: 1px solid var(--palette-primary-700);
    background: var(--palette-gray-70);
  }

  &.btnPillActive {
    background: var(--palette-primary-700);
    color: var(--palette-white);
  }
}

.btnSocialL {
  padding: 10px;
  border-radius: 6px;
  width: 48px;
  height: 48px;

  svg,
  img {
    width: 24px;
    height: 24px;
  }
}

.btnSocialS {
  padding: 6px;
  border-radius: 6px;
  width: 36px;
  height: 36px;

  svg,
  img {
    width: 24px;
    height: 24px;
  }
}

.btnSocialOutline {
  border: 1px solid var(--color-btn-social-outline-border);
  background-color: var(--color-btn-social-outline-bg);

  &:hover {
    border: 1px solid var(--color-btn-social-outline-hover-border);
    background-color: var(--color-btn-social-outline-hover-bg);
  }

  &:active {
    border: 1px solid var(--color-btn-social-outline-active-border);
    background-color: var(--color-btn-social-outline-active-bg);
  }
}

.btnSocialFilled {
  background-color: var(--color-btn-social-filled-bg);

  &:hover {
    background-color: var(--color-btn-social-filled-hover-bg);
  }
}

.btnPrimary {
  background: var(--color-btn-primary-bg);
  box-shadow:
    0 4px 0 0 var(--color-btn-primary-shadow),
    0 0 0 1px var(--color-btn-primary-border) inset;
  margin-bottom: 4px;
  color: var(--color-btn-primary-text);

  &:hover {
    background: var(--color-btn-primary-hover-bg);
  }

  &:active:not(:disabled) {
    background: var(--color-btn-primary-active-bg);
    box-shadow: none;
    transform: translateY(4px);
    transition: all 0.05s ease;
  }

  &:disabled {
    background: var(--color-btn-primary-disabled-bg);
    color: var(--color-btn-primary-disabled-text);
    box-shadow:
      0 4px 0 0 var(--color-btn-primary-disabled-shadow),
      0 0 0 1px var(--color-btn-primary-disabled-border) inset;
    cursor: auto;
  }
}

.btnPrimaryRed {
  background: var(--palette-red-700);
  box-shadow:
    0 4px 0 0 var(--palette-red-700-50),
    0 0 0 1px var(--color-btn-primary-border) inset;
  margin-bottom: 4px;
  color: var(--color-btn-primary-text);

  &:hover {
    background: var(--palette-red-800);
  }

  &:active:not(:disabled) {
    background: var(--palette-red-900);
    box-shadow: none;
    transform: translateY(4px);
    transition: all 0.05s ease;
  }

  &:disabled {
    background: var(--palette-red-500);
    color: var(--palette-red-300);
    box-shadow:
      0 4px 0 0 #ff4848,
      0 0 0 1px var(--palette-red-300) inset;
    cursor: auto;
  }
}

.btnFilled {
  background: var(--color-btn-filled-bg);
  border: 1.5px solid var(--color-btn-filled-border);
  color: var(--color-btn-filled-text);

  &:hover {
    background: var(--color-btn-filled-hover-bg);
    color: var(--color-btn-filled-hover-text);
  }

  &:active {
    border: 1.5px solid var(--color-btn-filled-active-border);
    background: var(--color-btn-filled-active-bg);
    color: var(--color-btn-filled-active-text);
  }
}

.btnOutline {
  background: var(--color-btn-outline-bg);
  color: var(--color-btn-outline-text);
  border: 1.5px solid var(--color-btn-outline-border);

  &:hover {
    background: var(--color-btn-outline-hover-bg);
    color: var(--color-btn-outline-hover-text);
    border: 1.5px solid var(--color-btn-outline-hover-border);
  }

  &:active {
    background: var(--color-btn-outline-active-bg);
    color: var(--color-btn-outline-active-text);
    border: 1.5px solid var(--color-btn-outline-active-border);
  }
}

.btnOutlinePrimary {
  box-shadow: 0 0 0 1.5px var(--palette-primary-700) inset;
  background: transparent;
  color: var(--palette-primary-700);

  &:hover {
    box-shadow: 0 0 0 1.5px var(--palette-primary-700) inset;
    background: var(--palette-primary-700);
    color: var(--palette-white);
  }

  &:active {
    box-shadow: 0 0 0 1.5px var(--palette-primary-500) inset;
    background: var(--palette-primary-500);
    color: var(--palette-white);
  }
}

@mixin btnLink_ {
  color: var(--color-btn-link-text);

  ////svg path {
  ////  fill: var(--color-btn-link-text);
  ////}

  /*
  Cause we have not common responsive properties for buttons, and each button have own responsive behaviour
  in case we have some weird not common/regular responsive behaviour, like

  mobile/tablet:
  XXS/M
  M/M
  M/L
  S/S
  XS/XS
  S/M

  we cant create and assign 10000 huge ugly classes like .btnMobileXXSTabletM

  and default .buttonResponsive (which is M on mobile and L on tablet+) not suit for us

  so only solution is to use buttons sizes SCSS mixins

  .action {
    composes: btn from '@/styles/buttons.module'
    composes: btnLink from '@/styles/buttons.module'
    composes: btnIconRight from '@/styles/buttons.module'
    @include button.btnS_;
    @include tablet {
      @include button.btnM_;
    }
  }

  BUT they overrides .btnLink padding 0, cause SCSS directives like @include dont match with
  our css modules and + css modules have higher specificity level (their declarations are below)

  so i added !important, it works :)
  */
  padding: 0 !important;

  &:hover {
    color: var(--color-btn-link-hover-text);

    ////svg path {
    ////  fill: var(--color-btn-link-hover-text);
    ////}
  }

  &:active {
    color: var(--color-btn-link-active-text);

    ////svg path {
    ////  fill: var(--color-btn-link-active-text);
    ////}
  }
}

.btnLink {
  @include btnLink_;
}
